import { t } from 'localization'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import Image from 'shared/components/Image'
import { FCLink, LinkCTA } from 'shared/components/Links'
import { HomepageQA } from 'shared/dataAttributes'
import { colors, fonts, media, pageWidth, styles } from 'shared/lib'
import styled from 'styled-components'

const TP = 'homepage.storesAndHistory'

const StoresAndHistory: FC<{}> = () => {
  const tv = useTranslation()
  return (
    <HomeStoresAndHistory>
      <ContentGroup>
        <Column data-qa={HomepageQA.StoresAndHistoryColumn} data-testid="storesAndHistoryColumn">
          <LinkCTA href="/storelocator">
            <ImageContainer>
              <Image
                data-qa={HomepageQA.OurStoresImage}
                src="/static/homepage/our_stores.png"
                alt="Flight Club Stores"
                width={580}
                height={408}
              />
            </ImageContainer>
          </LinkCTA>
          <Text>
            <Title data-qa={HomepageQA.OurStoresSectionTitle}>
              {t(`${TP}.flightClubStores`, 'Flight Club Stores')}
            </Title>
            <Description data-qa={HomepageQA.OurStoresDescriptionText}>
              <Trans
                i18nKey={`${TP}.withThreeRetail`}
                t={tv.t}
                defaults="With three retail locations in <0>New York,</0> <1>Los Angeles,</1> and <2>Miami,</2> Flight Club remains the premier source for everything sneakers."
                components={[
                  <FCLink qaAttr={HomepageQA.OurStoresNewYorkLink} href="/storelocator" />,
                  <FCLink qaAttr={HomepageQA.OurStoresLosAngelesLink} href="/storelocator" />,
                  <FCLink qaAttr={HomepageQA.OurStoresMiamiLink} href="/storelocator" />,
                ]}
              />
            </Description>
            <LinkCTA
              qaAttr={HomepageQA.OurStoresLearnMoreLink}
              href="/storelocator"
              data-testid="flightClubStoresLink"
            >
              {t(`${TP}.learnMore`, 'Learn More')}
            </LinkCTA>
          </Text>
        </Column>
        <Column data-qa={HomepageQA.StoresAndHistoryColumn} data-testid="storesAndHistoryColumn">
          <LinkCTA href="/about-us">
            <ImageContainer>
              <Image
                data-qa={HomepageQA.OurHistoryImage}
                src="/static/homepage/our_history.png"
                alt={t(`${TP}.ourHistory`, 'Our History')}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                width={580}
                height={408}
              />
            </ImageContainer>
          </LinkCTA>
          <Text>
            <Title data-qa={HomepageQA.OurHistorySectionTitle}>
              {t(`${TP}.ourHistory`, 'Our History')}
            </Title>
            <Description data-qa={HomepageQA.OurHistoryDescriptionText}>
              <Trans
                i18nKey={`${TP}.forOverADecade`}
                t={tv.t}
                /* tslint:disable:max-line-length */
                defaults="For over a decade, Flight Club has changed the landscape of sneaker retail. Carrying every brand name on the market, Flight Club has evolved from a one-stop sneaker destination, to a cultural hub for sneaker enthusiasts and novices alike.From <0>Air Jordans</0> to <1>Nike</1> to <2>Adidas</2> and more, we have it all."
                components={[
                  <FCLink qaAttr={HomepageQA.OurHistoryAirJordansLink} href="/air-jordans" />,
                  <FCLink qaAttr={HomepageQA.OurHistoryNikeLink} href="/nike" />,
                  <FCLink qaAttr={HomepageQA.OurHistoryAdidasLink} href="/adidas" />,
                ]}
              />
            </Description>
            <LinkCTA
              qaAttr={HomepageQA.OurHistoryLearnMoreLink}
              href="/about-us"
              data-testid="ourHistoryLink"
            >
              {t(`${TP}.learnMore`, 'Learn More')}
            </LinkCTA>
          </Text>
        </Column>
      </ContentGroup>
    </HomeStoresAndHistory>
  )
}

const HomeStoresAndHistory = styled.section`
  display: flex;
  justify-content: center;

  ${media.medium`
    padding: 40px 30px 0 30px;
    background-color: ${colors.FC2_WHITE};
  `}
`

const Column = styled.div`
  background-color: ${colors.FC2_WHITE};
  width: 100%;
  &:first-child {
    margin-bottom: 10px;
  }

  ${media.medium`
    margin: 10px;
  `}
`

const Text = styled.div`
  margin: 30px ${styles.mobilePageLayoutPadding} 40px;

  ${media.medium`
    margin: 30px 20px 50px 0;
  `}
`
const Title = styled.h2`
  ${fonts.SUBTITLE_2}
  margin: 30px 0 15px;

  ${media.large`
    margin-bottom: 10px;
  `}
`
const Description = styled.p`
  ${fonts.BODY_TEXT}
  line-height: 1.33;
  margin: 0 0 20px;

  ${media.medium`
    height: 75px;
  `}

  ${media.large`
    height: 55px;
  `}
`
const ContentGroup = styled.div`
  ${pageWidth}
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${media.medium`
    flex-wrap: nowrap;
    width: 100%;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  object-fit: contain;
  max-height: 408px;
  height: 274px;
  ${media.large`
    height: 253px;
  `}
  ${media.extraLarge`
    height: 408px;
  `}
`

export default StoresAndHistory
